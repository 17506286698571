define("discourse/plugins/discourse-global-communities/discourse/components/topic-list-notify-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "discourse/lib/notification-levels", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _service, _notificationLevels, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicListNotifyButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "agcTopicListWatching", [_service.service]))();
    #agcTopicListWatching = (() => (dt7948.i(this, "agcTopicListWatching"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "watching", [_tracking.tracked], function () {
      return false;
    }))();
    #watching = (() => (dt7948.i(this, "watching"), void 0))();
    constructor() {
      super(...arguments);
      this.show = this.currentUser?.id !== this.args.topic.creator?.id;
      this.watchingLevel = _notificationLevels.topicLevels.find(tl1 => tl1.key === "watching")?.id;
      this.watching = this.args.topic.notification_level === this.watchingLevel;
      this.loading = false;
    }
    get containerClassNames() {
      return `notify-button__click-container ${this.watching ? "-watching" : "-regular"}`;
    }
    get text() {
      return _discourseI18n.default.t(`notification_levels.${this.watching ? "notified" : "notify"}`);
    }
    async _toggleWatching(e1) {
      e1.preventDefault();
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.watching = await this.agcTopicListWatching.toggleWatching(this.args.topic);
      this.loading = false;
      this.appEvents.trigger("agc-custom:user_notify_toggle_click", {
        watching: this.watching
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "_toggleWatching", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.show}}
          <div class="meta-follow">
            <a
              role="button"
              class={{this.containerClassNames}}
              {{on "click" this._toggleWatching}}
            >
              {{icon (if this.watching "d-watching" "d-regular")}}
              <span class="notify-text">
                {{this.text}}
              </span>
            </a>
          </div>
        {{/if}}
      
    */
    {
      "id": "tJwD2elt",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[10,0],[14,0,\"meta-follow\"],[12],[1,\"\\n        \"],[11,3],[24,\"role\",\"button\"],[16,0,[30,0,[\"containerClassNames\"]]],[4,[32,0],[\"click\",[30,0,[\"_toggleWatching\"]]],null],[12],[1,\"\\n          \"],[1,[28,[32,1],[[52,[30,0,[\"watching\"]],\"d-watching\",\"d-regular\"]],null]],[1,\"\\n          \"],[10,1],[14,0,\"notify-text\"],[12],[1,\"\\n            \"],[1,[30,0,[\"text\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/topic-list-notify-button.js",
      "scope": () => [_modifier.on, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicListNotifyButton;
});