define("discourse/plugins/discourse-global-communities/discourse/components/program-pillars/load-more-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LoadMoreButton extends _component.default {
    static shouldRender(args1, context1, owner1) {
      const router1 = owner1.lookup("service:router");
      return router1.currentRouteName === "program-pillars";
    }
    static #_ = (() => dt7948.g(this.prototype, "agcProgramPillars", [_service.service]))();
    #agcProgramPillars = (() => (dt7948.i(this, "agcProgramPillars"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    constructor() {
      super(...arguments);
      // reset the "Load More" button pressed state to false when the component is initialized
      this.agcProgramPillars.loadMorePressed = false;
    }
    get show() {
      return this.args.outletArgs.canLoadMore && !this.agcProgramPillars.loadMorePressed;
    }
    loadMore() {
      this.agcProgramPillars.loadMorePressed = true;
      // forces the page to be scrolled by 1px which triggers the check to see if more elements need to be loaded
      // the infinite scrolling works by monitoring when the page is scrolled and detecting if more elements need to be
      // loaded.
      window.scrollBy(0, 1);
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.show}}
          <DButton @label="agc.topic-list.more" @action={{this.loadMore}} />
        {{/if}}
      
    */
    {
      "id": "Kg0iGOKD",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@label\",\"@action\"],[\"agc.topic-list.more\",[30,0,[\"loadMore\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/program-pillars/load-more-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LoadMoreButton;
});