define("discourse/plugins/discourse-global-communities/discourse/components/see-all-comments-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/url", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _url, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SeeAllCommentsButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    get show() {
      return this.args.topic.get("filter") === "amazon_responses" || this.args.topic.get("filter") === "guided_responses";
    }
    async onClick() {
      const params1 = new URLSearchParams(window.location.search);
      if (params1.has("filter")) {
        window.history.replaceState({}, "", `${location.pathname}?${params1}`);
      }
      this.args.topic.postStream.cancelFilter();
      await this.args.topic.postStream.refresh();
      _url.default.jumpToPost("1", {
        anchor: "comments-container"
      });
      this.appEvents.trigger("enhanced_user_analytics:event", "page_view", {}, {
        clickRef: "CTA_see_all_comments"
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "onClick", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.show}}
          <DButton
            @translatedLabel={{i18n "agc.responses_filter.see_all_comments"}}
            @action={{this.onClick}}
          />
        {{/if}}
      
    */
    {
      "id": "mNcSVFfJ",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@translatedLabel\",\"@action\"],[[28,[32,1],[\"agc.responses_filter.see_all_comments\"],null],[30,0,[\"onClick\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/see-all-comments-button.js",
      "scope": () => [_dButton.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SeeAllCommentsButton;
});