define("discourse/plugins/discourse-global-communities/discourse/components/agc-topic-list-actions", ["exports", "@glimmer/component", "@ember/modifier", "@ember/service", "truth-helpers", "discourse/helpers/concat-class", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/formatter", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/topic-list-notify-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _service, _truthHelpers, _concatClass, _ajax, _ajaxError, _formatter, _dIcon, _i18n, _decorators, _discourseI18n, _topicListNotifyButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcTopicListActions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get canBeLiked() {
      return this.args.topic.get("topic_post_can_like");
    }
    get heartIcon() {
      if (this.args.topic.get("topic_first_post_liked")) {
        return "d-liked";
      }
      return this.canBeLiked ? "d-unliked" : "heart-disabled-squid";
    }
    async toggleLikes() {
      if (!this.canBeLiked) {
        return;
      }
      const postId1 = this.args.topic.topic_first_post_id;
      const firstPostLiked1 = this.args.topic.topic_first_post_liked;
      let likeCount1 = this.args.topic.get("like_count");
      if (firstPostLiked1) {
        try {
          await (0, _ajax.ajax)(`/post_actions/${postId1}`, {
            type: "DELETE",
            data: {
              post_action_type_id: 2
            }
          });
          likeCount1 -= 1;
          this.args.topic.set("topic_first_post_liked", false);
          this.args.topic.set("like_count", likeCount1 < 0 ? 0 : likeCount1);
        } catch (error1) {
          (0, _ajaxError.popupAjaxError)(error1);
        }
      } else {
        try {
          await (0, _ajax.ajax)("/post_actions", {
            type: "POST",
            data: {
              id: postId1,
              post_action_type_id: 2
            }
          });
          this.args.topic.set("topic_first_post_liked", true);
          this.args.topic.set("like_count", likeCount1 + 1);
        } catch (error1) {
          (0, _ajaxError.popupAjaxError)(error1);
        }
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleLikes", [_decorators.bind]))();
    get commentsLabel() {
      if (!this.siteSettings.comments_label_on_topic_list) {
        return;
      }
      return _discourseI18n.default.t("agc.topic-list.comment", {
        count: this.args.topic.replyCount
      });
    }
    get likesLabel() {
      if (!this.siteSettings.likes_label_on_topic_list) {
        return;
      }
      return _discourseI18n.default.t("agc.topic-list.like", {
        count: this.args.topic.like_count
      });
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="meta-actions">
          {{#unless @topic.isPrivateMessage}}
            <div
              {{on "click" this.toggleLikes}}
              role="button"
              class={{concatClass "meta-likes" (if this.canBeLiked "can-like")}}
            >
              {{icon this.heartIcon}}
              <span>
                {{number @topic.like_count}}
                {{#if this.likesLabel}}{{this.likesLabel}}{{/if}}
              </span>
            </div>
          {{/unless}}
    
          <div class="meta-comments">
            <a href={{@topic.url}}>{{icon "comment"}}</a>
            <span>
              {{number @topic.replyCount noTitle="true"}}
              {{#if this.commentsLabel}}{{this.commentsLabel}}{{/if}}
            </span>
          </div>
    
          {{#if @showViews}}
            <div class="meta-views">
              {{icon "eye-squid"}}
              {{number @topic.views noTitle="true"}}
              <div class="views-label">
                {{i18n "agc.views"}}
              </div>
            </div>
          {{/if}}
    
          {{#unless (or @topic.isPrivateMessage @hideNotifyButton)}}
            <TopicListNotifyButton @topic={{@topic}} />
          {{/unless}}
        </span>
      
    */
    {
      "id": "CfmoBlXS",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"meta-actions\"],[12],[1,\"\\n\"],[41,[51,[30,1,[\"isPrivateMessage\"]]],[[[1,\"        \"],[11,0],[24,\"role\",\"button\"],[16,0,[28,[32,0],[\"meta-likes\",[52,[30,0,[\"canBeLiked\"]],\"can-like\"]],null]],[4,[32,1],[\"click\",[30,0,[\"toggleLikes\"]]],null],[12],[1,\"\\n          \"],[1,[28,[32,2],[[30,0,[\"heartIcon\"]]],null]],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[1,[28,[32,3],[[30,1,[\"like_count\"]]],null]],[1,\"\\n            \"],[41,[30,0,[\"likesLabel\"]],[[[1,[30,0,[\"likesLabel\"]]]],[]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"meta-comments\"],[12],[1,\"\\n        \"],[10,3],[15,6,[30,1,[\"url\"]]],[12],[1,[28,[32,2],[\"comment\"],null]],[13],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,3],[[30,1,[\"replyCount\"]]],[[\"noTitle\"],[\"true\"]]]],[1,\"\\n          \"],[41,[30,0,[\"commentsLabel\"]],[[[1,[30,0,[\"commentsLabel\"]]]],[]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"        \"],[10,0],[14,0,\"meta-views\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[\"eye-squid\"],null]],[1,\"\\n          \"],[1,[28,[32,3],[[30,1,[\"views\"]]],[[\"noTitle\"],[\"true\"]]]],[1,\"\\n          \"],[10,0],[14,0,\"views-label\"],[12],[1,\"\\n            \"],[1,[28,[32,4],[\"agc.views\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[51,[28,[32,5],[[30,1,[\"isPrivateMessage\"]],[30,3]],null]],[[[1,\"        \"],[8,[32,6],null,[[\"@topic\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@topic\",\"@showViews\",\"@hideNotifyButton\"],false,[\"unless\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-topic-list-actions.js",
      "scope": () => [_concatClass.default, _modifier.on, _dIcon.default, _formatter.number, _i18n.default, _truthHelpers.or, _topicListNotifyButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcTopicListActions;
});