define("discourse/plugins/discourse-global-communities/discourse/components/program-pillars/side-widget-topic-link", ["exports", "@glimmer/component", "@ember/render-modifiers/modifiers/did-insert", "discourse/helpers/raw", "discourse/helpers/topic-link", "discourse-common/utils/decorators", "@ember/component", "@ember/template-factory"], function (_exports, _component, _didInsert, _raw, _topicLink, _decorators, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LoadMoreButton extends _component.default {
    decorateTopicLinkWithAnalyticsDataAttr(element1) {
      const topicLinkEl1 = element1.querySelector("a");
      if (topicLinkEl1) {
        topicLinkEl1.dataset.agcAnalyticsRef = this.args.tagName;
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "decorateTopicLinkWithAnalyticsDataAttr", [_decorators.bind]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="program-pillars-side-widget links link"
          {{didInsert this.decorateTopicLinkWithAnalyticsDataAttr}}
        >
          {{topicLink @topic}}
          {{raw
            "topic-post-badges"
            unreadPosts=@topic.unread_posts
            unseen=@topic.unseen
            url=@topic.lastUnreadUrl
          }}
        </div>
      
    */
    {
      "id": "et4kXv62",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"program-pillars-side-widget links link\"],[4,[32,0],[[30,0,[\"decorateTopicLinkWithAnalyticsDataAttr\"]]],null],[12],[1,\"\\n      \"],[1,[28,[32,1],[[30,1]],null]],[1,\"\\n      \"],[1,[28,[32,2],[\"topic-post-badges\"],[[\"unreadPosts\",\"unseen\",\"url\"],[[30,1,[\"unread_posts\"]],[30,1,[\"unseen\"]],[30,1,[\"lastUnreadUrl\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@topic\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/program-pillars/side-widget-topic-link.js",
      "scope": () => [_didInsert.default, _topicLink.default, _raw.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LoadMoreButton;
});