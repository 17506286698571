define("discourse/plugins/discourse-global-communities/discourse/components/hero-video", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "ember-modifier", "discourse/plugins/discourse-global-communities/discourse/helpers/videojs-initializer", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template2, _emberModifier, _videojsInitializer, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HeroVideo extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    setupVideo = (() => (0, _emberModifier.modifier)(async element1 => {
      const video1 = element1.querySelector("video");
      if (video1) {
        const transformedVideo1 = await (0, _videojsInitializer.default)(video1, this.appEvents);
        transformedVideo1.on("play", this.args.onPlay);
        transformedVideo1.on("pause", this.args.onPause);
      }
    }))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div {{this.setupVideo}}>
          {{htmlSafe @html}}
        </div>
      
    */
    {
      "id": "aFQs5yZA",
      "block": "[[[1,\"\\n    \"],[11,0],[4,[30,0,[\"setupVideo\"]],null,null],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,1]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@html\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/hero-video.js",
      "scope": () => [_template2.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HeroVideo;
});